/* eslint-disable no-undef  */
let accessibeInit = false;

export const initAccessibe = ({ forTeacher } = { forTeacher: false }) => {
  const s = document.createElement('script');
  const h = document.querySelector('head') || document.body;
  s.src = process.env.REACT_APP_USERWAY_WIDGET_URL;
  // s['data-account'] = process.env.REACT_APP_USERWAY_WIDGET_URL;
  s.setAttribute('data-account', '4A1oyjwZw0');

  // eslint-disable-next-line no-console
  console.log(forTeacher);
  /* uncomment the following line to override default position */
  s.setAttribute('data-position', forTeacher ? 5 : 3);
  /* uncomment the following line to override default size (values: small, large) */
  s.setAttribute('data-size', 'small');
  /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.) */
  /* s.setAttribute("data-language", "language"); */
  /* uncomment the following line to override color set via widget (e.g., #053f67) */
  s.setAttribute('data-color', '#146FF8');
  /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text) */
  /* s.setAttribute("data-type", "1"); */
  /* s.setAttribute("data-statement_text:", "Our Accessibility Statement"); */
  /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility")"; */
  /* uncomment the following line to override support on mobile devices */
  /* s.setAttribute("data-mobile", true); */
  /* uncomment the following line to set custom trigger action for accessibility menu */
  /* s.setAttribute("data-trigger", "triggerId") */

  h.appendChild(s);
  accessibeInit = true;
};

// export const initAccessibe = ({ forTeacher } = { forTeacher: false }) => {
//   const s = document.createElement('script');
//   const h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';

//   const position = forTeacher ? 'left' : 'right';

//   s.async = true; s.onload = () => {
//     acsbJS.init({
//       statementLink: '',
//       footerHtml: '',
//       hideMobile: false,
//       hideTrigger: false,
//       disableBgProcess: false,
//       language: 'en',
//       position,
//       leadColor: '#146FF8',
//       triggerColor: '#146FF8',
//       triggerRadius: '50%',
//       triggerPositionX: position,
//       triggerPositionY: 'bottom',
//       triggerIcon: 'people',
//       triggerSize: 'bottom',
//       triggerOffsetX: 90,
//       triggerOffsetY: 72,
//       mobile: {
//         triggerSize: 'small',
//         triggerPositionX: position,
//         triggerPositionY: 'bottom',
//         triggerOffsetX: 90,
//         triggerOffsetY: 72,
//         triggerRadius: '20',
//       },
//     });
//   }; h.appendChild(s);
//   accessibeInit = true;
// };

export const destroyAccessibe = () => {
  try {
    if (acsbJS) {
      acsbJS.destroy();
    }
  } catch (_e) {
    // ignore
  }
};

// eslint-disable-next-line no-unused-vars
export const showAccessibeChatIcon = () => {
  if (!accessibeInit) {
    return;
  }
  const acsbElement = document.querySelector('.acsb-trigger');
  if (acsbElement) {
    acsbElement.classList.remove('acsb-trigger-hide');
  }
};

// eslint-disable-next-line no-unused-vars
export const hideAccessibeChatIcon = () => {
  if (!accessibeInit) {
    return;
  }
  const acsbElement = document.querySelector('.acsb-trigger');
  if (acsbElement) {
    acsbElement.classList.add('acsb-trigger-hide');
  }
};

export const hideAccessbeChatIconInTimeInterval = () => {
  if (!accessibeInit) {
    return;
  }
  let i = 0;
  const t = setInterval(() => {
    const acsbElement = document.querySelector('.acsb-trigger');
    if (acsbElement) {
      hideAccessibeChatIcon();
      clearInterval(t);
    } else if (i > 2400) {
      // wait for 2 minute
      clearInterval(t);
    }

    i++;
  }, 50);
};
